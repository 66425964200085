.app-header__language {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    display: flex;
    color: white;
}

.app-header__language-selecting {
    font-weight: 600;
}

$file-height: 8rem;
$file-width: 8rem;

.file-upload__item {
    position: relative;
    height: $file-height;
    width: $file-width;
    margin-right: 1rem;
    cursor: pointer;
}

.file-upload__item-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 22px !important;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px;
}
.file-upload__item-loading {
    position: absolute;
    top: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.2);
    height: $file-height;
    width: $file-width;
    align-items: center;
    justify-content: center;
    display: flex;
}

.file-upload__item-image {
    height: $file-height;
    width: $file-width;
    object-fit: cover;
}

.file-upload__item-footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    flex-direction: row;
}

.app-content {
    flex-grow: 1;
    overflow: scroll;
    padding-bottom: 3rem !important;
    background: rgba(251, 251, 251, 0.8);
}

@media screen and (max-width: 576px) {
    .app-header {
        justify-content: space-between !important;
        align-items: center !important;
    }

    .app-header__language {
        position: relative !important;
    }

    .app-content {
        padding-bottom: 0rem !important;
    }
}
